/* styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: Shabnam;
  src: url(./assets/fonts/Shabnam-Light.woff2) format("woff2"),
    url(./assets/fonts/Shabnam-Light.woff2) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Shabnam;
  src: url(./assets/fonts/Shabnam-Bold.woff2) format("woff2"),
    url(./assets/fonts/Shabnam-Bold.woff2) format("woff");
  font-weight: 700;
}

@font-face {
  font-family: Shabnam;
  src: url(./assets/fonts/Shabnam.woff2) format("woff2"),
    url(./assets/fonts/Shabnam.woff2) format("woff");
  font-weight: 500;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  direction: rtl;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: Shabnam;
}



a {
  color: inherit;
  text-decoration: none;
}

.list-style-inside {
  list-style:inside
}

.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-25 {
  font-size: 25px;
}

.fw-700 {
  font-weight: 700;
}

.max-width-1200 {
  max-width: 1200px !important
}

.mb-2 {
  margin-bottom: 5px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-24 {
  padding: 24px;
}

.text-green {
  color: green;
}

.text-red {
  color: #AA2E25;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.pointer {
  cursor: pointer;
}

.z-index-2 {
  z-index: 2;
}

.max-content {
  width: max-content;
}


.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:disabled {
  color: #000000;
  background-color: #d8d8d8;
  border-color: #9fa0a0;
  cursor: no-drop;
}

.input-form-dashboard-step {
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(66, 80, 102, 0.40);
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(66, 80, 102, 0.10);
}

.form-order {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 560px;
  margin: 0 auto;
  background-color: #f5f5f5;
}

.form-order-small {
  color: #a6a6a6
}

.payment-body {
  margin: 25px auto;
  max-width: 350px;
  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px 8px 24px 0px rgb(0 0 0 / 28%)
}






/* table orders */
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}


.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: #FAFAFA;
  padding: 10px;
}

.pagination-button {
  cursor: pointer;
  background-color: #FAFAFA;
  border: 1px solid #ddd;
  color: #333;
  padding: 8px 12px;
  margin: 0 4px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #ddd;
}

.pagination-button.active {
  background-color: #333;
  color: #fff;
}